import _startsWith from 'lodash/startsWith';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';
import _toLower from 'lodash/toLower';
import languages from '../utils/config/languages';
import i18n from './i18n';
import envUtils from './utils';
import webUtils from './webUtils';
import _includes from 'lodash/includes';
import _lastIndexOf from 'lodash/lastIndexOf';
import langCode from './langCode';
import cookieUtils from './cookieUtils';
import deviceUtils from './deviceUtils';
import _indexOf from 'lodash/indexOf';
import pathUtils from './pathUtils';
import demoStoreLink from './config/demoStoreLink';
import _isEmpty from 'lodash/isEmpty';
import _some from 'lodash/some';
import _values from 'lodash/values';
import userUtils from './userUtils';

const utils = {
  isPrerenderHydration: () =>
    document.getElementById('root').hasChildNodes() &&
    utils.isMobile() &&
    !deviceUtils.isPrerenderCrawler() &&
    window.location.hostname.indexOf('/business/showcase') === -1, // workaround for demostore hydration issue (eBug:SMB240320-0010)
  isExternalUrl: url => _startsWith(url, 'http'),
  isMakeupArUrl: () => window.location.hostname.indexOf('makeupar.com') !== -1,
  isMsrFormPreview: () =>
    (envUtils.isStage || envUtils.isDemo) &&
    _some(
      _values(window.location.ancestorOrigins),
      origin =>
        _includes(origin, 'eperfect') || _includes(origin, 'pfc-test-dt')
    ),
  isBlogUrl: () => {
    const url = webUtils.removePathnameLang(window.location.pathname);
    if (!url) {
      return false;
    }
    if (
      url.indexOf('/consumer/blog/') === -1 &&
      url.indexOf('/business/blog/') === -1
    ) {
      return false;
    }
    return parseInt(url.slice(15, 16)) != url.slice(15, 16);
  },
  transformMakeupArUrl: url =>
    url && utils.isMakeupArUrl()
      ? url.replaceAll('.perfectcorp.com/', '.makeupar.com/')
      : url
      ? url
      : 'initial',
  isMobile: (w = window.innerWidth) => w <= 600,
  isPad: (w = window.innerWidth) => w > 600 && w <= 1024,
  isDesktop: (w = window.innerWidth) => w > 1024,
  isYoutube: url => url && url.indexOf('youtube') !== -1,
  isBusiness: () => {
    if (typeof window === 'undefined') return;
    const p = _get(window, 'location.pathname', '/');
    if (p === '/') {
      return true;
    }
    return p.indexOf('/business') !== -1;
  },
  isConsumerHomePage: url => {
    if (url) {
      return url.endsWith('/consumer');
    }
    if (typeof window === 'undefined') return;
    const p = _get(window, 'location.pathname', '/');
    return !!p.endsWith('/consumer');
  },
  isInvestors: () => {
    if (typeof window === 'undefined') return;
    const p = _get(window, 'location.pathname', '/');
    if (p === '/') {
      return true;
    }
    return p.indexOf('/investors') !== -1;
  },
  isGcse: () => {
    if (typeof window === 'undefined') return;
    const p = _get(window, 'location.pathname', '/');
    if (p === '/') {
      return true;
    }
    return p.indexOf('/gcse') !== -1;
  },
  isSmb: () => {
    if (typeof window === 'undefined') return;
    const p = _get(window, 'location.pathname', '/');
    return p.indexOf('/business/solutions/online-service') !== -1;
  },
  isEnterprise: () => {
    if (typeof window === 'undefined') return;
    const p = _get(window, 'location.pathname', '/');
    return (
      p.indexOf('/business/solutions/enterprise') !== -1 ||
      p.indexOf('/business/products') !== -1 ||
      p.indexOf('/business/services') !== -1 ||
      p.indexOf('/business/showcase/youcam-tutorial') !== -1 ||
      p.indexOf('/business/showcase/lip-liner') !== -1 ||
      p.indexOf('/business/showcase/earrings') !== -1 ||
      p.indexOf('/business/showcase/eye-wear') !== -1 ||
      p.indexOf('/business/showcase/eyebrow') !== -1 ||
      p.indexOf('/business/showcase/eye-color') !== -1 ||
      p.indexOf('/business/showcase/concealer') !== -1 ||
      p.indexOf('/business/showcase/bronzer') !== -1 ||
      p.indexOf('/business/showcase/contour') !== -1 ||
      p.indexOf('/business/showcase/hair-color') !== -1 ||
      p.indexOf('/business/showcase/virtual-hairstyles') !== -1 ||
      p.indexOf('/business/showcase/beard-dye') !== -1 ||
      p.indexOf('/business/showcase/beard-style') !== -1 ||
      p.indexOf('/business/showcase/shadefinder') !== -1 ||
      p.indexOf('/business/showcase/skincare') !== -1 ||
      p.indexOf('/business/showcase/nail-color') !== -1 ||
      p.indexOf('/business/showcase/face-analyzer') !== -1 ||
      p.indexOf('/business/showcase/watches') !== -1 ||
      p.indexOf('/business/showcase/bracelets') !== -1 ||
      p.indexOf('/business/showcase/rings') !== -1 ||
      p.indexOf('/business/showcase/set') !== -1 ||
      p.indexOf('/business/showcase/personality') !== -1 ||
      p.indexOf('/business/showcase/necklace') !== -1 ||
      p.indexOf('/business/showcase/3d-viewer') !== -1
    );
  },
  isAndroid: () => /android/i.test(navigator.userAgent),
  isIos: () => !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
  is2cBlog: () => {
    if (typeof window === 'undefined') return;
    const p = _get(window, 'location.pathname', '/');
    if (p === '/') {
      return true;
    }
    return p.indexOf('/consumer/blog/') !== -1;
  },
  isLine: () => /LINE/i.test(navigator.userAgent),
  isInstagram: () => /instagram/i.test(navigator.userAgent),
  isSony: () =>
    /401SO|402SO|501SO|502SO|601SO|602SO|701SO|D200✕|D21✕✕|D2202|D2203|D2206|D2212|D2243|D2302|D2303|D2305|D2306|D240✕|D2502|D2533|D510✕|D5303|D5306|D5322|D5788|D58✕✕|D65✕✕|D6563|D6603|D6616|D6633|D6643|D6646|D6653|D6683|D6708|E2003|E2006|E2033|E2043|E2053|E2104|E2105|E2114|E2115|E2124|E2303|E2306|E2312|E2333|E2353|E2363|E5303|E5306|E5333|E5343|E5353|E5363|E5506|E5533|E5553|E5563|E5603|E5606|E5633|E5643|E5653|E5663|E58✕✕|E6533|E6553|E6583|E6603|E6633|E6653|E6683|E6833|E6853|E6883|F3111|F3112|F3113|F3115|F3116|F3211|F3212|F3213|F3215|F3216|F3311|F3313|F5121|F5122|F5321|F8131|F8132|F8331|F8332|G2199|G2299|G3112|G3116|G3121|G3123|G3125|G3212|G3221|G3223|G3226|G3311|G3312|G3313|G3412|G3416|G3421|G3423|G3426|G8141|G8142|G8231|G8232|G8341|G8342|G8343|G8441|H3113|H3123|H3133|H3213|H3223|H3311|H3321|H4113|H4133|H4213|H4233|H4311|H4331|L50t|L50u|L50w|L55t|L55u|M50w|S50h|S55t|S55u|S55w|SO-01G|SO-01H|SO-01J|SO-01K|SO-02G|SO-02H|SO-02J|SO-02K|SO-03F|SO-03G|SO-03H|SO-03J|SO-04F|SO-04G|SO-04H|SO-04J|SOL25|SOL26|SOV31|SOV32|SOV33|SOV34|SOV35|SOV36|XM50h|XM50t/i.test(
      navigator.userAgent
    ),
  isWeixin: () => /weixin|micromessenger/i.test(navigator.userAgent),
  isChrome64: () => /Chrome\/64/i.test(navigator.userAgent),
  isSafari: () => {
    if (
      /CriOS/i.test(navigator.userAgent) &&
      /iphone|ipod|ipad/i.test(navigator.userAgent)
    ) {
      return false;
    }
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  },
  isPictureTagSupported: () => !!window.HTMLPictureElement,
  isWebPSupported: () => {
    const elem = document.createElement('canvas');
    if (!!(elem.getContext && elem.getContext('2d'))) {
      // was able or not to get WebP representation
      return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
    }

    // very old browser like IE 8, canvas not supported
    return false;
  },
  isFreeTrial: () => {
    if (typeof window === 'undefined') return;
    const p = _get(window, 'location.pathname', '/');
    return p.indexOf('/free-trial') !== -1;
  },
  isIE: () => !!document.documentMode,
  isEdge: () => !utils.isIE() && !!window.StyleMedia,
  isFirefox: () => typeof InstallTrigger !== 'undefined',
  isWithinIframe: () => window.location !== window.parent.location,
  isSublink: (pathname, paths = []) => {
    if (!_isArray(paths)) {
      if (paths === '/business/global-beauty-tech-forum') {
        const lang =
          !(i18n.language === 'en' || !i18n.language) &&
          `/${i18n.language.replace('_', '-').toLowerCase()}`;
        return lang === 'en'
          ? pathname === paths
          : pathname === `${lang}${paths}`;
      }
      return pathname.indexOf(paths) !== -1;
    }
    for (const path of paths) {
      if (pathname.indexOf(path) !== -1) {
        return true;
      }
    }
    return false;
  },
  isSameLink: (pathname, paths = [], lang) => {
    if (pathname.indexOf('2021') !== -1) {
      return pathname === '/fr' + paths;
    }
    if (!_isArray(paths)) {
      return pathname.indexOf(paths) !== -1;
    }
    for (const path of paths) {
      if (pathname.indexOf(path) !== -1) {
        return true;
      }
    }
    return false;
  },
  isSelfLink: url =>
    url.indexOf('perfectcorp.com') !== -1 || url.indexOf('makeupar.com') !== -1,
  isEventPageLink: url => url.indexOf('events') !== -1,
  getNewsId: url => {
    if (url.indexOf('?id=') !== -1) {
      return url.split('?id=')[1];
    }
    return url.split('/')[url.split('/').length - 1];
  },
  determineLanguage: (language = [], isBusiness = utils.isBusiness()) => {
    let langNoLocale = language;
    if (
      language.length > 2 &&
      ['zh_cn', 'zh-cn', 'zh_tw', 'zh-tw'].indexOf(language.toLowerCase()) ===
        -1
    ) {
      langNoLocale = language.substring(0, 2);
    }
    let lang = _find(
      isBusiness ? languages.business : languages.consumer,
      l => l.i18nKey.toLowerCase() === langNoLocale
    );
    if (!lang) {
      lang = _find(
        isBusiness ? languages.business : languages.consumer,
        l => l.code.toLowerCase() === langNoLocale
      );
    }
    return lang && lang.code ? lang.code : 'en';
  },
  getSupportedLanguages: () => {
    const langs = utils.isBusiness() ? languages.business : languages.consumer;
    return langs.map(l => l.i18nKey.toLowerCase());
  },
  isConsumerOnlyLanguage: lang => {
    const langs = languages.consumer.filter(
      lc =>
        !languages.business.find(
          lb => lb.i18nKey.toLowerCase() === lc.i18nKey.toLowerCase()
        )
    );
    return !!langs.find(lc => lc.i18nKey.toLowerCase() === lang.toLowerCase());
  },
  getCountryByLanguage: language => {
    return new Promise(resolve => {
      if (language === 'en') {
        return resolve('US');
      }
      if (language === 'zh-tw') {
        return resolve('TW');
      }
      if (utils.isChs(language)) {
        return resolve('CN');
      }
      if (language === 'ja') {
        return resolve('JP');
      }
      if (language === 'ko') {
        return resolve('KR');
      }
      if (language === 'de') {
        return resolve('DE');
      }
      if (language === 'fr') {
        return resolve('FR');
      }
      if (language === 'es') {
        return resolve('ES');
      }
      if (language === 'pt') {
        return resolve('PT');
      }
      if (language === 'it') {
        return resolve('IT');
      }
      return resolve('US');
    });
  },
  changeLanguage: l => {
    i18n.changeLanguage(l).then(() => {
      const {pathname, search} = window.location;
      window.location.href =
        l === 'en'
          ? webUtils.removePathnameLang(pathname, search)
          : `/${l.replace('_', '-').toLowerCase()}${webUtils.removePathnameLang(
              pathname
            )}${search}`;
    });
  },
  changeHeaderLanguage: l => {
    utils.changeLanguage(l);
  },
  changeFooterLanguage: l => {
    utils.changeLanguage(l);
    window.scrollTo(0, 0);
  },
  getYmkForShopifyAppUrl: () =>
    'https://apps.shopify.com/youcam-makeup-official?locale=' +
    utils.determineLanguage(i18n.language).replace('_', '-'),
  isCnDomain: () => {
    if (typeof window === 'undefined') return;
    const host = window.location.hostname;
    return (
      host.indexOf('perfectcorp.cn') !== -1 ||
      cookieUtils.getCookie('_cn_') == 1
    );
  },
  isChs: language => ['zh_cn', 'zh-cn'].includes(_toLower(language)),
  isCht: language => ['zh_tw', 'zh-tw'].includes(_toLower(language)),
  isEn: language => ['en'].includes(_toLower(language)),
  isKo: language => ['ko'].includes(_toLower(language)),
  isJa: language => ['ja'].includes(_toLower(language)),
  removePathnameLang: (url, search = '') => {
    if (url.indexOf('/business') !== -1) {
      return `${url.substring(url.indexOf('/business'))}${search}`;
    } else if (url.indexOf('/consumer') !== -1) {
      return `${url.substring(url.indexOf('/consumer'))}${search}`;
    } else if (url.indexOf('/nft') !== -1) {
      return `${url.substring(url.indexOf('/nft'))}${search}`;
    }
  },
  getPathnameLang: pathname => {
    const params = pathname.split('/');
    if (['business', 'consumer', 'nft'].indexOf(params[1]) !== -1) return 'en';
    return params[1] || 'en';
  },
  getTwoLettersLang: (
    lang = webUtils.getPathnameLang(window.location.pathname)
  ) => {
    switch (lang) {
      case 'zh-cn':
        return 'cn';
      case 'zh-tw':
        return 'tw';
      default:
        return lang;
    }
  },
  convertToUst: datetime => {
    const dt = new Date(datetime);
    dt.setTime(dt.getTime() + dt.getTimezoneOffset() * 60 * 1000);
    return new Date(dt.getTime());
  },
  convertToFrenchMonth: month => {
    const frenchMonth = [
      'JAN',
      'FÉV',
      'MAR',
      'AVR',
      'MAI',
      'JUN',
      'JUL',
      'AOÛ',
      'SEP',
      'OCT',
      'NOV',
      'DÉC',
    ];
    return frenchMonth[month];
  },
  isNewHeader: () => {
    if (typeof window === 'undefined') return;
    const path = _get(window, 'location.pathname', '/');
    const newHeaderList = [
      '/business',
      '/consumer',
      '/consumer/apps/ymk',
      '/consumer/apps/ycp',
      '/consumer/apps/ycn',
      '/consumer/apps/yca',
      '/consumer/apps/ycv',
      '/business/campaign/smb',
      '/business/campaign/ai-skin-diagnostic',
      '/business/campaign/ai-skin-analysis',
      '/business/campaign/vto',
      '/business/campaign/virtual-makeup',
      '/business/menus',
      '/consumer/menus',
    ];
    if (window.location.href.indexOf('/consumer/features') !== -1) return true;
    if (_startsWith(path, '/business') || _startsWith(path, '/consumer')) {
      return newHeaderList.indexOf(path) !== -1;
    }
    if (!path.match(/\/(fr|ja|zh-cn|zh-tw|ko|pt|es|de|it)/)) {
      return false;
    } else {
      return newHeaderList.indexOf(webUtils.removePathnameLang(path)) !== -1;
    }
  },
  twitterLink: () => {
    if (webUtils.isJa()) return 'https://twitter.com/youcammake';
    return 'https://twitter.com/youcamapps';
  },
  ytLink: () => {
    if (webUtils.isJa())
      return 'https://www.youtube.com/channel/UCvb76vuw_lla1ELqb-WaUnA';
    if (webUtils.isBusiness())
      return 'https://www.youtube.com/channel/UC7GeoFYO277cNdRa_k8TWug';
    return 'https://www.youtube.com/channel/UCExTYT6XYEt_bT6qs7m-X0A';
  },
  fbLink: () => {
    if (webUtils.isCht(i18n.language))
      return 'https://www.facebook.com/YouCamApps.tw';
    if (webUtils.isJa()) return 'https://www.facebook.com/perfectcorp.jp';
    return 'https://www.facebook.com/youcamapps/';
  },
  jobLink: () => {
    if (webUtils.isCht(i18n.language))
      return 'https://www.104.com.tw/company/1a2x6bjgwu';
    return 'https://www.linkedin.com/company/perfect-corp';
  },
  igLink: () => {
    if (webUtils.isCht(i18n.language))
      return 'https://www.instagram.com/youcamapps.tw/';
    if (webUtils.isJa()) return 'https://www.instagram.com/youcammake/';
    return 'https://instagram.com/youcamapps/';
  },
  arrayHasSameElement: (arr1, arr2) => {
    const tmp = arr1.map(s => {
      return _includes(arr2, s);
    });
    return _includes(tmp, true);
  },
  getPardotDomain: () => {
    if (
      typeof window !== 'undefined' &&
      window.location.hostname.indexOf('makeupar') !== -1
    ) {
      return 'makeupar';
    }
    return 'perfectcorp';
  },
  pardotUrlDomainChange: url => {
    if (
      typeof window !== 'undefined' &&
      window.location.hostname.indexOf('makeupar') !== -1
    ) {
      return url.replace('perfectcorp', 'makeupar');
    }
    return url;
  },
  getEnvReactAppUrl: () => {
    if (typeof window !== 'undefined' && webUtils.isMakeupArUrl()) {
      return process.env.REACT_APP_MAKEUPAR_FRONTEND_URL;
    }
    if (typeof window !== 'undefined' && webUtils.isCnDomain()) {
      return process.env.REACT_APP_CN_FRONTEND_URL;
    }
    return process.env.REACT_APP_URL;
  },
  getSMBEnvReactAppUrl: ({url = '', solutionType = ''}) => {
    const lang = webUtils.convertToSmbLang(i18n.language);
    const qs = `?lang=${lang}${
      solutionType ? `&solutionType=${solutionType}` : ''
    }`;
    if (typeof window !== 'undefined' && webUtils.isMakeupArUrl()) {
      return `${process.env.REACT_APP_MAKEUPAR_SMB_BACKEND_URL}${url}${qs}`;
    }
    if (typeof window !== 'undefined' && webUtils.isCnDomain()) {
      return `${process.env.REACT_APP_CN_SMB_BACKEND_URL}${url}${qs}`;
    }
    return `${process.env.REACT_APP_SMB_BACKEND_URL}${url}${qs}`;
  },
  getSMBPartnerEnvReactAppUrl: () => {
    if (typeof window !== 'undefined' && webUtils.isMakeupArUrl()) {
      return process.env.REACT_APP_MAKEUPAR_PARTNER_BACKEND_URL;
    }
    if (typeof window !== 'undefined' && webUtils.isCnDomain()) {
      return process.env.REACT_APP_CN_PARTNER_BACKEND_URL;
    }
    return process.env.REACT_APP_PARTNER_BACKEND_URL;
  },
  getTagsPfByFunctionType: () => {
    const params = new URLSearchParams(window.location.search);
    const functionType = params.get('functionType') || 'MAKEUP';
    const solutionType = webUtils.getInitialSolutionType();
    return `Free Trial (SMB)_${
      functionType === 'EYEWEAR'
        ? 'Eyewear'
        : functionType === 'SKINCARE_PRO'
        ? solutionType === 'AESTHETIC_SIMULATION'
          ? 'SkincarePro_Simulation'
          : 'Skincare Pro'
        : 'Makeup'
    }`;
  },
  getInitialSolutionType: () => {
    const params = new URLSearchParams(window.location.search);
    const functionType = params.get('functionType') || 'MAKEUP';
    if (params.get('solutionType') && params.get('solutionType') !== 'null') {
      return params.get('solutionType');
    }
    if (functionType === 'MAKEUP' || functionType === 'EYEWEAR')
      return functionType;
    return 'SKIN_ANALYSIS';
  },
  getUrlReg: () => {
    return /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
  },
  getUserAgentInApp: index => {
    if (typeof window === 'undefined' && !_get(window, 'navigator.userAgent'))
      return '';
    if (
      window.navigator.userAgent.indexOf('com.perfectcorp.ymkbusiness') ===
        -1 &&
      window.navigator.userAgent.indexOf('com.perfectcorp.en.ymkbusiness') ===
        -1
    )
      return 'notInApps';
    return window.navigator.userAgent
      .slice(
        _lastIndexOf(window.navigator.userAgent, '(') + 1,
        _lastIndexOf(window.navigator.userAgent, ')')
      )
      .split(' ')[index];
  },
  isWithGa: () => {
    return (
      webUtils.getUserAgentInApp() === 'notInApps' ||
      webUtils.getUserAgentInApp(3) === 'withga'
    );
  },
  isInYmkApp: () => {
    return (
      webUtils.getUserAgentInApp(0) === 'com.perfectcorp.ymkbusiness' ||
      webUtils.getUserAgentInApp(0) === 'com.perfectcorp.en.ymkbusiness'
    );
  },
  isAppDownloadLinks: link => {
    return (
      link &&
      (link.indexOf('https://youmakeup.page.link/') !== -1 ||
        link.indexOf('https://youperfect.page.link/') !== -1 ||
        link.indexOf('https://youvideo.page.link/') !== -1 ||
        link.indexOf('https://youcamnail.page.link/') !== -1 ||
        link.indexOf('https://yce.page.link/') !== -1)
    );
  },
  regionFilter: res => {
    if (
      !res ||
      res.toLowerCase() === 'not found' ||
      res === 'IR' ||
      res === 'RU' ||
      (res === 'CN' && !webUtils.isCnDomain())
    )
      return '';
    return res;
  },
  convertToSmbLang: lang => {
    if (
      webUtils.isChs(lang) ||
      webUtils.isCht(lang) ||
      lang === 'ja' ||
      lang === 'ko' ||
      lang === 'de' ||
      lang === 'fr' ||
      lang === 'es' ||
      lang === 'pt'
    )
      return langCode.convertLanguageCode(lang);
    return 'enu';
  },
  isWindowLoaded: () => {
    if (!document) {
      return false;
    }
    return (
      document.readyState === 'complete' ||
      document.readyState === 'interactive'
    );
  },
  getOnlineServiceLink: solutionType => {
    if (
      (solutionType && solutionType === 'SKIN_ANALYSIS') ||
      window.location.pathname.indexOf('skincare-pro') !== -1
    ) {
      return {
        feature: '/business/solutions/online-service/skincare-pro',
        plan: '/business/solutions/online-service/skincare-pro/plan',
        functionType: 'SKINCARE_PRO',
        strapiEnum: 'ONLINE_SERVICE_SKINCARE_PRO',
        solutionType: 'SKIN_ANALYSIS',
        faq: 'PlanPricingPageForSkincarePro',
      };
    }
    if (
      (solutionType && solutionType === 'EYEWEAR') ||
      window.location.pathname.indexOf('eye-wear') !== -1
    ) {
      return {
        feature: '/business/solutions/online-service/eye-wear',
        plan: '/business/solutions/online-service/eye-wear/plan',
        functionType: 'EYEWEAR',
        strapiEnum: 'ONLINE_SERVICE_EYEWEAR',
        solutionType: 'EYEWEAR',
        faq: 'PlanPricingPageForEyewear',
      };
    }
    if (
      (solutionType && solutionType === 'AESTHETIC_SIMULATION') ||
      window.location.pathname.indexOf('aesthetic-simulation') !== -1
    ) {
      return {
        feature: '/business/solutions/online-service/aesthetic-simulation',
        plan: '/business/solutions/online-service/aesthetic-simulation/plan',
        functionType: 'SKINCARE_PRO',
        strapiEnum: 'ONLINE_SERVICE_AESTHETIC_SIMULATION',
        solutionType: 'AESTHETIC_SIMULATION',
        faq: 'PlanPricingPageForSkincareProSimulation',
      };
    }
    return {
      feature: '/business/solutions/online-service/makeup',
      plan: '/business/solutions/online-service/makeup/plan',
      functionType: 'MAKEUP',
      strapiEnum: 'ONLINE_SERVICE',
      solutionType: 'MAKEUP',
      faq: 'PlanPricingPage',
    };
  },
  getLangPath: l => {
    if (!l || l === 'en' || l === 'en-in') return '';
    return `/${l.replace('_', '-').toLowerCase()}`;
  },
  nonEEA: country => {
    if (!country) return false;
    const countryList = [
      'AT',
      'BE',
      'BG',
      'HR',
      'CY',
      'CZ',
      'DK',
      'EE',
      'FI',
      'FR',
      'DE',
      'GR',
      'HU',
      'IE',
      'IT',
      'LV',
      'LT',
      'LU',
      'MT',
      'PL',
      'PT',
      'RO',
      'SK',
      'SI',
      'ES',
      'SE',
      'GB',
      'NL',
    ];
    return _indexOf(countryList, country) === -1;
  },
  goToDemostore: () => {
    const productPathname = window.location.pathname.split('/');
    const defaultUrl = pathUtils.addLangPrefix(
      '/business/showcase/skincare/home'
    );
    if (productPathname.indexOf('products') === -1)
      return (window.location.href = defaultUrl);
    const pageType = productPathname[productPathname.indexOf('products') + 1];
    const page = demoStoreLink.filter(d => d.key === pageType);
    if (!_isEmpty(page)) {
      return (window.location.href = pathUtils.addLangPrefix(
        _get(page[0], 'value')
      ));
    }
    return (window.location.href = defaultUrl);
  },
  goToOnlineService: (i18n, functionType, solutionType = functionType) => {
    const lang = i18n === 'ja' ? '/ja' : '';
    const domain = webUtils.isCnDomain()
      ? 'https://www.perfectcorp.cn'
      : webUtils.getEnvReactAppUrl();
    switch (solutionType) {
      case 'EYEWEAR':
        return `${domain}${lang}/business/showcase/online-service/eye-wear`;
      case 'MAKEUP':
        return `${domain}${lang}/business/showcase/online-service/`;
      case 'SKIN_ANALYSIS':
        return webUtils.goToFreeTrial(functionType, solutionType);
      case 'AESTHETIC_SIMULATION':
        return webUtils.goToFreeTrial(functionType, solutionType);
      default:
        return '';
    }
  },
  goToFreeTrial: (functionType, solutionType) => {
    return `/business/user/free-trial?functionType=${functionType}&solutionType=${solutionType}`;
  },
  goToSubscription: ({
    functionType,
    solutionType,
    currencyType,
    recurringType,
    subscriptionType,
    addPreDevice,
    numberOfLicenses,
  }) => {
    const numberOfLicenseQueryString = numberOfLicenses
      ? `&numberOfLicenses=${numberOfLicenses}`
      : '';
    const addDevice = addPreDevice ? `&addonsQuantity=${addPreDevice}` : '';
    return (
      `/business/user/sign-up/${subscriptionType.toLowerCase()}?platform=STANDARD&recurringType=${recurringType}&currencyType=${currencyType}&functionType=${functionType}&solutionType=${solutionType}` +
      numberOfLicenseQueryString
    );
  },
  isAsia: lang => {
    return (
      webUtils.isJa(lang) ||
      webUtils.isChs(lang) ||
      webUtils.isCht(lang) ||
      webUtils.isKo(lang)
    );
  },
  redirectFromComToCn: countryCode => {
    if (
      webUtils.isCnDomain() &&
      window.location.hostname.indexOf('perfectcorp.com') !== -1
    ) {
      return (window.location.href = window.location.href.replace(
        process.env.REACT_APP_URL,
        process.env.REACT_APP_CN_FRONTEND_URL
      ));
    }
  },
  convertToWebp: img => {
    if (!img) return '';
    return img.replace(/\.(png|jpg)$/i, '.webp');
  },
  checkImgFormat: img => {
    if (!img) return '';
    if (img.indexOf('.png') !== -1) return 'image/png';
    if (img.indexOf('.webp') !== -1) return 'image/webp';
    if (img.indexOf('.svg') !== -1) return 'image/svg+xml';
    return 'image/jpeg';
  },
  checkWebpSupport: () => {
    const canvas = document.createElement('canvas');
    return (
      canvas.getContext &&
      canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0
    );
  },
  redirectAfterSignIn: (accountSummary, i18n) => {
    const subscriptions = _get(accountSummary, 'subscriptions', []);
    const activeSolutionType = subscriptions.filter(s => !s.isOverdue);
    const inActiveSolutionType = subscriptions.filter(s => s.isOverdue);
    if (_isEmpty(activeSolutionType)) {
      window.location.href = userUtils.getUserHomeUrl(
        accountSummary.account.email,
        i18n.language
      );
      return;
    }

    if (activeSolutionType.length === 1) {
      const now = Date.now();

      const hasRecentInactive = inActiveSolutionType.some(
        s => now < s.endTime + 30 * 24 * 60 * 60 * 1000
      );

      if (!hasRecentInactive) {
        window.location.href = webUtils.getSMBEnvReactAppUrl({
          solutionType: _get(activeSolutionType, '[0].solutionType', 'MAKEUP'),
        });
        return;
      }
    }
    window.location.href = userUtils.getUserHomeUrl(
      accountSummary.account.email,
      i18n.language
    );
  },
};

export default utils;
